const ShoppingCartIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="notification"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3759 6.86788C21.5944 6.08896 20.3299 6.08998 19.5497 6.87014L13.4202 12.9996C13.0991 13.3208 12.6782 13.523 12.2268 13.5732C10.3479 13.782 9.25495 11.508 10.5918 10.1712L20.5726 0.190384C20.7648 -0.0466416 21.1199 -0.0651504 21.3357 0.150613L31.0426 9.85755C32.3794 11.1944 31.2849 13.4681 29.4059 13.2594C28.9557 13.2093 28.5355 13.0079 28.2147 12.688L22.3759 6.86788Z"
        fill="#FFB800"
      />
      <rect x="11" y="22" width="4" height="11" rx="2" fill="#FFB800" />
      <rect x="19" y="22" width="4" height="11" rx="2" fill="#FFB800" />
      <rect x="27" y="22" width="4" height="11" rx="2" fill="#FFB800" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00042 15C1.20183 15 -0.192514 16.5718 0.0217803 18.3574L2.30178 37.3574C2.48272 38.8652 3.7618 40 5.28039 40H35.9574C37.476 40 38.7551 38.8652 38.936 37.3574L41.216 18.3574C41.4303 16.5718 40.0359 15 38.2374 15H3.00042ZM5.92492 19C4.70782 19 3.77288 20.0781 3.945 21.2827L5.80215 34.2827C5.94289 35.2681 6.78673 36 7.78203 36H33.4557C34.451 36 35.2949 35.2681 35.4356 34.2827L37.2928 21.2827C37.4649 20.0781 36.53 19 35.3129 19H5.92492Z"
        fill="#FFB800"
      />
    </svg>
  );
};
export default ShoppingCartIcon;
